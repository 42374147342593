/** @jsx jsx */
import { Box, jsx } from "theme-ui";
// import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import banner from "../../static/imgs/heartfulness-green-donation-banner.jpg";
import LayoutHome from "../layout/LayoutHome";

const CanadaDonationRecurring = () => {
  const renderContentHeader = () => (
    <div
      className="banner-gatsby"
      sx={{
        paddingTop: "30px",
        alignItems: "center",
        minHeight: "620px",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundImage: `url(${banner})`,
      }}
    >
      <div sx={{ textAlign: "center" }}>
        <h2 sx={{ color: "#fff", fontSize: "55px", marginTop: "10px" }}>
          <span sx={{ display: "inline-block" }}>
            Heartfulness Canada Donations - Recurring
          </span>{" "}
        </h2>
      </div>
    </div>
  );

  return (
    <LayoutHome
      seoTitle="Heartfulness Donations for Canada - Recurring"
      contentHeader={renderContentHeader()}
    >
      <Box sx={{ marginTop: "30px" }}>
        <h3>General donations to Heartfulness Institute, Canada - Recurring</h3>
        <b>We need your financial support to help us touch many more hearts.</b>
        <p sx={{ marginTop: "15px" }}>
          Share in the work of bringing peace, compassion, happiness and harmony
          to people in all walks of life by making a financial gift to
          Heartfulness Institute, Canada.
        </p>
        <p>
          Donations of any size make it possible for us to change lives by
          teaching and promoting Heartfulness techniques. These programs are led
          and conducted by volunteers and are taught free of charge to
          participants. Our teams of dedicated volunteers donate their time and
          talents, often in their professional fields, and regularly cover their
          own expenses.
        </p>
        <p>
          Your donation will support our programs in schools, universities and
          colleges, corporations, and hospitals, as well as any interested
          groups and communities.
        </p>
        <Box>
          <h3>Sign-up for Automatic Recurring Donations:</h3>
          <p>
            To sign-up for automated monthly donations, please download and
            submit the completed Auto donation form to
            <a href="mailto:ca.treasurer@srcm.org" sx={{ marginLeft: "5px" }}>
              ca.treasurer@srcm.org.
            </a>
          </p>
          <p>
            <a
              href="https://cdn-prod.heartfulness.org/donations-doc/Auto-Donation-Form.pdf"
              target="_blank"
              rel="noreferrer"
            >
              Auto donation form PDF
              <StaticImage
                src="../../../apps/static/img/pdficon.png"
                alt="Logo"
                sx={{ marginTop: "5px" }}
              />
            </a>
            <br />
            inquiries or to cancel your donation, please contact
            <a href="mailto:ca.treasurer@srcm.org" sx={{ marginLeft: "5px" }}>
              ca.treasurer@srcm.org
            </a>
          </p>
          <h3>Donate by E-transfer:</h3>
          <p>
            The easiest way to donate is by sending an E-transfer to
            <a
              href="mailto:us.ca.treasurer@srcm.org"
              sx={{ marginLeft: "5px" }}
            >
              ca.treasurer@srcm.org
            </a>
          </p>
          <p>
            Please provide your name, address, phone number and email address in
            the message box prior to sending, along with the purpose of your
            donation.
          </p>
          <h3>Donate by Cheque:</h3>
          <p>
            Kindly make cheques payable to Heartfulness Institute and mail them
            to:
          </p>
          <p>
            Heartfulness Institute – Treasurer’s Office
            <br />
            1559 Bayshire Drive
            <br />
            Oakville, ON L6H 6E5
          </p>
        </Box>
        <h3>A note about donations received in Canada:</h3>
        <p>
          All donations made are voluntary and are suggested to be made to the
          general fund of Heartfulness Institute. General funds are used for
          projects in areas of COVID-19 care, the environment and green
          initiatives, positive lifestyle, and sustainability initiatives, among
          others.
        </p>
        <p>
          {" "}
          If you would like to make a gift for a specific purpose, please
          contact the Treasurer at{" "}
          <a href="mailto:ca.treasurer@srcm.org">ca.treasurer@srcm.org</a>{" "}
          beforehand with your request.
        </p>
      </Box>
    </LayoutHome>
  );
};

export default CanadaDonationRecurring;
